import api from "@/api";

export const state = {
    dates: null,
    times: null
};

export const getters = {};

export const mutations = {
    SET_DATES(state, res) {
        state.dates = res;
    },
    SET_TIMES(state, res) {
        state.times = res;
    }
};

export const actions = {
    async getDates({ commit }, params) {
        try {
            const res = await api.methods.getBookDates(params)
            if(res['data']['success']) {
                commit('SET_DATES', res['data']['data'])
            }
        }
        catch (e) {
            console.error(e)
            commit('params/SET_CUR_SECTION', 'ErrorSection', { root:true });
        }
    },
    async getTimes({ commit }, params) {
        try {
            const res = await api.methods.getBookTimes(params)
            //console.log(res);
            if(res['data']['success']) {
                commit('SET_TIMES', res['data']['data'])
            }
        }
        catch (e) {
            console.error(e)
            commit('params/SET_CUR_SECTION', 'ErrorSection', { root:true });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
