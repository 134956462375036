<template>
  <div>
    <Loader v-if="!datetime.dates" />
    <div v-else>
      <VueDatePicker
          v-model="date"
          locale="ru"
          model-type="dd.MM.yyyy"
          :allowed-dates="datetime.dates.booking_dates"
          @update:model-value="selectDate" inline auto-apply :enable-time-picker="false"></VueDatePicker>
      <div class="info-label" v-if="!date">Выберите дату</div>
      <Loader v-else-if="!datetime.times" />
      <div v-else class="list-dates">
        <div v-for="v in datetime.times" :key="v.id">
          <div class="list-date" @click="selectTime(v)">
            {{v.time}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import Loader from "@/components/Loader";
export default {
  name: 'DateTimeSection',
  data() {
    return {
      allowedDates: {

      },
      date: null
    }
  },
  computed: {
    ...mapState(['companies', 'params', 'staffs', 'datetime'])
  },
  methods: {
    selectTime(time) {
      let curSection = 'ServicesSection'
      if(this.params.selServices.length > 0 && this.params.selStaff) {
        curSection = 'MainSection'
      }
      else if(this.params.selServices.length > 0) {
        curSection = 'StaffsSection'
      }
      this.$store.dispatch('params/setSelTime', time.time)
      this.$store.dispatch('params/setTitleBlockDatetime', this.params.selDate + " " + this.params.selTime)
      this.$store.dispatch('params/setCurSection', curSection)
    },
    selectDate(modelData) {
      this.$store.dispatch('params/setSelDate', modelData)
      let params = {
        filialId: this.params.selCompany.id,
        datetime: modelData
      };
      if(this.params.selStaff) {
        params.staffId = this.params.selStaff.id
      }
      if(this.params.selServices) {
        params.services = this.params.selServices;
      }
      this.$store.dispatch('datetime/getTimes', params)
    }
  },
  created() {
    this.datetime.times = null
    let params = {
      filialId: this.params.selCompany.id
    };
    if(this.params.selStaff) {
      params.staffId = this.params.selStaff.id
    }
    if(this.params.selServices) {
      params.services = this.params.selServices;
    }
    this.$store.dispatch('datetime/getDates', params)
  },
  components: {
    Loader,
    VueDatePicker
  }
}
</script>

<style lang="scss" scoped>
.dp__theme_light {
  --dp-menu-width: 380px;
  --dp-menu-min-width: 380px !important;
  --dp-font-size: 16px;

  .dp__cell_offset:not(.dp__cell_disabled) {
    color: #2d2d2d !important;
  }

  @media (max-width: 430px) {
    --dp-menu-width: 100%;
    --dp-menu-min-width: 100% !important;
  }
}
.info-label {
  margin-top: 10px;
}
.list-dates {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}
.list-date {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  background: white;
  border-radius: 8px;
  width: 90px;
  margin-right: 10px;
  margin-bottom: 10px;

  &:hover {
    opacity: .8;
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
  }

  &__address {
    font-size: 14px;
  }
}
</style>
