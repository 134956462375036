<template>
  <div class="header-widget">
    <div class="close-btn" @click="setShowWidget()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 19.05L18.9998 5.05023" stroke="black" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
        <path d="M19 19.05L5.00018 5.05023" stroke="black" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
      </svg>
    </div>
    <img alt="Vue logo" src="https://assets.yclients.com/general/0/06/06df518233f368d_20180119160014.png">
    <div class="info">
      <div v-if="params.selCompany">
        <div>{{params.selCompany.title}}</div>
        <div class="links">
          <div class="links__item" @click="getCompanySection()">О филиале</div>
          <div class="links__item" @click="getCompaniesSection()">Филиалы</div>
        </div>
      </div>
      <div><h1>{{ msg }}</h1></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'WidgetHeader',
  props: {
    msg: String
  },
  computed: {
    ...mapState(['companies', 'params']),
  },
  methods: {
    setShowWidget() {
      this.$store.dispatch('params/setShowWidget', false)
    },
    getCompanySection() {
      this.$store.dispatch('params/setCurSection', 'CompanySection')
      this.$store.dispatch('params/setBackLink', 'MainSection')
    },
    getCompaniesSection() {
      this.$store.dispatch('params/setCurSection', 'CompaniesSection')
      this.$store.dispatch('params/setBackLink', 'MainSection')
    },
    getCompany() {
      if(this.companies.companies.includes(this.params.selCompany())) {
        //let index = this.companies.companies.indexOf(this.params.selCompany());
        //return
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.header-widget {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  .close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  img {
    width: 80px;
    margin-right: 15px;
  }

  h1 {
    font-size: 18px;
  }
}
.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  .links {
    display: flex;

    > div {
      margin-right: 15px;
      font-size: 13px;
      cursor: pointer;

      &:hover {
        opacity: .9;
      }
    }
  }
}
</style>
