<template>
  <div>
    <Loader v-if="!companies.companies" />
    <div v-else v-for="v in companies.companies" :key="v.id">
      <div class="list-filial" @click="selectFilial(v)">
        <div class="list-filial__title">{{v.title}}</div>
        <div class="list-filial__address">{{v.address}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from "@/components/Loader";
export default {
  name: 'CompaniesSection',
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState(['companies', 'params', 'services'])
  },
  methods: {
    selectFilial(company) {
      this.$store.dispatch('params/setSelCompany', company)
      this.$store.dispatch('params/setSelServices', [])
      this.$store.dispatch('params/setSelDate', null)
      this.$store.dispatch('params/setSelTime', null)
      this.$store.dispatch('params/setSelStaff', null)
      this.$store.dispatch('params/setTitleBlockStaff', 'Выбрать специалиста')
      this.$store.dispatch('params/setTitleBlockServices', 'Выбрать услуги')
      this.$store.dispatch('params/setTitleBlockDatetime', 'Выбрать время')

      if(this.params.defaultServiceId) {
        let params = {
          filialId: this.params.selCompany.id
        };
        let ds = this.params.defaultServiceId
        this.$store.dispatch('services/getServices', params).then(() => {
          let st = this.$store

          this.services.services.services.forEach(function(el){
            if(el.id == ds) {
              st.dispatch('params/setSelServices', [el.id])
              st.dispatch('params/setTitleBlockServices', el.title)
            }
          })
        })

      }

      this.$store.dispatch('params/setCurSection', 'MainSection')
      //this.$store.dispatch('params/setBackLink', 'CompaniesSection')
      //console.log(this.params.curSection)
    }
  },
  created() {
    this.$store.dispatch('companies/getCompanies', 1000)
    this.isLoading = false
  },
  components: {
    Loader
  }
}
</script>

<style lang="scss" scoped>
.list-filial {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 15px;
  text-align: left;
  background: white;
  border-radius: 8px;

  &:hover {
    opacity: .8;
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
  }

  &__address {
    font-size: 14px;
  }
}
</style>
