<template>
  <div class="main-section">
    <div class="control-btn" @click="selectSection('StaffsSection')">
      <div class="control-btn__title">
        {{ params.titleBlockStaff }}
      </div>
      <div class="control-btn__label" v-if="params.selStaff">
        Изменить
      </div>
    </div>
    <div class="control-btn" @click="selectSection('DateTimeSection')">
      <div>{{ params.titleBlockDateTime }}</div>
      <div class="control-btn__label" v-if="params.selTime">
        Изменить
      </div>
    </div>
    <div class="control-btn" @click="selectSection('ServicesSection')">
      <div>{{ params.titleBlockServices }}</div>
      <div class="control-btn__label" v-if="params.selServices.length">
        Изменить
      </div>
    </div>
    <div class="control-btn" v-if="false" @click="log()">тест</div>
    <div
        class="btn-confirm"
        @click="selectSection('RecordSection')"
        v-if="params.selStaff && params.selDate && params.selTime && params.selServices.length > 0"
    >Записаться</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'mainSection',
  data() {
    return {
      text: '123',
      isVisible: true
    }
  },
  computed: {
    ...mapState(['companies', 'params', 'staffs'])
  },
  methods: {
    log() {
      console.log(this.params.selStaff)
      console.log(this.params.selDate)
      console.log(this.params.selTime)
      console.log(this.params.selServices)
    },
    selectSection(val) {
      this.$store.dispatch('params/setCurSection', val)
      //this.$store.dispatch('params/setLoading', false)
      this.$store.dispatch('params/setBackLink', 'MainSection')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.control-btn {
  padding: 20px;
  background: white;
  border-radius: 8px;
  margin-top: 5px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    opacity: .8;
  }

  &__label {
    font-weight: normal;
    font-size: 12px;
    margin-top: 3px;
  }
}
</style>
