import api from "@/api";

export const state = {
    staffs: null,
    staff: null
};

export const getters = {};

export const mutations = {
    SET_STAFFS(state, res) {
        state.staffs = res;
    },
    SET_STAFF_INFO(state, res) {
        state.staff = res;
    }
};

export const actions = {
    async getStaffs({ commit }, params) {
        try {
            const res = await api.methods.getBookStaff(params)
            if(res['data']['success']) {
                commit('SET_STAFFS', res['data']['data'])
            }
        }
        catch (e) {
            console.error(e)
            commit('params/SET_CUR_SECTION', 'ErrorSection', { root:true })
        }
    },
    async getStaff({ commit }, params) {
        try {
            const res = await api.methods.getCompany(params)
            if(res['data']['success']) {
                commit('SET_STAFF_INFO', res['data']['data'])
            }
        }
        catch (e) {
            console.error(e)
            commit('params/SET_CUR_SECTION', 'ErrorSection', { root:true })
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
