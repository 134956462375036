export const state = {
    selCompanyObj: {},
    selCompany: null,
    selStaff: null,
    selDate: null,
    selTime: null,
    selServices: [],
    userName: null,
    userPhone: null,
    userSmsCode: null,
    heading: "Выберите филиал",
    titleBlockStaff: "Выбрать специалиста",
    titleBlockDateTime: "Выбрать время",
    titleBlockServices: "Выбрать услуги",
    isLoading: true,
    curSection: 'CompaniesSection',
    backLink: null,
    statusRecord: null,
    utm: null,
    showWidget: false,
    defaultServiceId: null,
    metrikaCounter: null,
    customPrice: null,
};

export const getters = {
    isLoading(state) {
        return state.isLoading
    },
    curSection(state) {
        return state.curSection
    },
    selCompany(state) {
        return state.selCompany
    }
};

export const mutations = {
    SET_LOADING(state, res) {
        state.isLoading = res;
    },
    SET_CUR_SECTION(state, res) {
        state.curSection = res;
    },
    SET_BACK_LINK(state, res) {
        state.backLink = res;
    },
    SET_SEL_COMPANY(state, res) {
        state.selCompany = res;
    },
    SET_HEADING(state, res) {
        state.heading = res;
    },
    SET_SEL_STAFF(state, res) {
        state.selStaff = res;
    },
    SET_TITLE_BLOCK_STAFF(state, res) {
        state.titleBlockStaff = res
    },
    SET_TITLE_BLOCK_DATETIME(state, res) {
        state.titleBlockDateTime = res
    },
    SET_TITLE_BLOCK_SERVICES(state, res) {
        state.titleBlockServices = res
    },
    SET_SEL_DATE(state, res) {
        state.selDate = res;
    },
    SET_SEL_TIME(state, res) {
        state.selTime = res;
    },
    SET_SEL_SERVICES(state, res) {
        state.selServices = res;
    },
    SET_USER_NAME(state, res) {
        state.userName = res;
    },
    SET_USER_PHONE(state, res) {
        state.userPhone = res;
    },
    SET_USER_SMS_CODE(state, res) {
        state.userSmsCode = res;
    },
    SET_STATUS_RECORD(state, res) {
        state.statusRecord = res;
    },
    SET_UTM(state, res) {
        state.utm = res;
    },
    SET_SHOW_WIDGET(state, res) {
        state.showWidget = res;
    },
    SET_DEFAULT_SERVICE_ID(state, res) {
        state.defaultServiceId = res
    },
    SET_METRIKA_COUNTER(state, res) {
        state.metrikaCounter = res;
    },
    SET_CUSTOM_PRICE(state, res) {
        state.customPrice = res;
    }
};

export const actions = {
    setLoading({ commit }, param) {
        commit('SET_LOADING', param)
    },
    setCurSection({ commit, dispatch }, param) {
        commit('SET_CUR_SECTION', param)
        if(param == 'CompaniesSection') {
            commit('SET_HEADING', "Выберите филиал");
            dispatch("eventAction", "YC_WIDGET_SHOW_COMPANIES_SECTION")
        }
        else if(param == 'CompanySection') {
            commit('SET_HEADING', "О филиале");
            dispatch("eventAction", "YC_WIDGET_SHOW_COMPANY_SECTION")
        }
        else if(param == 'StaffsSection') {
            commit('SET_HEADING', "Выберите специалиста");
            dispatch("eventAction", "YC_WIDGET_SHOW_STAFFS_SECTION")
        }
        else if(param == 'DateTimeSection') {
            commit('SET_HEADING', "Выберите время");
            dispatch("eventAction", "YC_WIDGET_SHOW_DATETIME_SECTION")
        }
        else if(param == 'ServicesSection') {
            commit('SET_HEADING', "Выберите услугу");
            dispatch("eventAction", "YC_WIDGET_SHOW_SERVICES_SECTION")
        }
        else if(param == 'RecordSection') {
            commit('SET_HEADING', "Ваши данные");
            dispatch("eventAction", "YC_WIDGET_SHOW_RECORD_SECTION")
        }
        else if(param == 'SmsCodeSection') {
            commit('SET_HEADING', "Код из смс");
            commit('SET_BACK_LINK', null)
        }
        else if(param == 'SuccessSection') {
            commit('SET_HEADING', "Вы записаны!");
            commit('SET_BACK_LINK', null)
            dispatch("eventAction", "YC_WIDGET_SHOW_SUCCESS_SECTION")
        }
        else if(param == 'ErrorSection') {
            commit('SET_HEADING', "Ошибка!");
            commit('SET_BACK_LINK', null)
            dispatch("eventAction", "YC_WIDGET_SHOW_ERROR_SECTION")
        }
        else if(param == 'DataProcessing') {
            commit('SET_HEADING', "Обработка данных");
        }
        else {
            commit('SET_HEADING', "Выберите действие");
            dispatch("eventAction", "YC_WIDGET_SHOW_MAIN_SECTION")
        }
    },
    setSelStaff({ commit, dispatch }, param) {
        commit('SET_SEL_STAFF', param)
        if(param) dispatch("eventAction", "YC_WIDGET_SEL_STAFF")
    },
    setBackLink({ commit }, param) {
        commit('SET_BACK_LINK', param)
    },
    setSelCompany({ commit, dispatch }, param) {
        commit('SET_SEL_COMPANY', param)
        dispatch("eventAction", "YC_WIDGET_SEL_COMPANY")
    },
    setHeading({ commit }, param) {
        commit('SET_HEADING', param)
    },
    setTitleBlockStaff({ commit }, param) {
        commit('SET_TITLE_BLOCK_STAFF', param)
    },
    setTitleBlockDatetime({ commit }, param) {
        commit('SET_TITLE_BLOCK_DATETIME', param)
    },
    setTitleBlockServices({ commit }, param) {
        commit('SET_TITLE_BLOCK_SERVICES', param)
    },
    setSelDate({ commit, dispatch }, param) {
        commit('SET_SEL_DATE', param)
        if(param) dispatch("eventAction", "YC_WIDGET_SEL_DATE")
    },
    setSelTime({ commit, dispatch }, param) {
        commit('SET_SEL_TIME', param)
        if(param) dispatch("eventAction", "YC_WIDGET_SEL_TIME")
    },
    setSelServices({ commit, dispatch }, param) {
        commit('SET_SEL_SERVICES', param)
        if(param.length) dispatch("eventAction", "YC_WIDGET_SEL_SERVICES")
    },
    setUserName({ commit }, param) {
        commit('SET_USER_NAME', param)
    },
    setUserPhone({ commit }, param) {
        commit('SET_USER_PHONE', param)
    },
    setUserSmsCode({ commit }, param) {
        commit('SET_USER_SMS_CODE', param)
    },
    setStatusRecord({ commit }, param) {
        commit('SET_STATUS_RECORD', param)
    },
    setUtm({ commit }, param) {
        commit('SET_UTM', param)
    },
    setShowWidget({ commit, dispatch }, param) {
        let body = document.querySelector('body')
        if(param) {
            body.classList.add('over-widget')
            dispatch("eventAction", "YC_WIDGET_SHOW")
        }
        else {
            body.classList.remove('over-widget')
            dispatch("eventAction", "YC_WIDGET_CLOSE")
        }
        commit('SET_SHOW_WIDGET', param)
    },
    setDefaultServiceId({ commit }, param) {
        commit('SET_DEFAULT_SERVICE_ID', param)
    },
    setMetrikaCounter({ commit }, param) {
        commit('SET_METRIKA_COUNTER', param)
    },
    setCustomPrice({ commit }, param) {
        commit('SET_CUSTOM_PRICE', param)
    },
    // eslint-disable-next-line no-unused-vars
    eventAction({ commit }, param) {
        //console.log(state.metrikaCounter);
        console.log(param);
        if(state.metrikaCounter) {
            state.metrikaCounter.reachGoal(param);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
