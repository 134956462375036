<template>
  <div class="form-record">
    <div class="rec-info">Вы успешно записаны!</div>
    <div class="record-info">
      <div class="record-info__title">Специалист</div>
      <div class="record-info__value">{{params.selStaff.name}}</div>
    </div>
    <div class="record-info">
      <div class="record-info__title">Дата и время</div>
      <div class="record-info__value">{{params.selDate}} {{params.selTime}}</div>
    </div>
    <div class="record-info">
      <div class="record-info__title">Услуги</div>
      <div class="record-info__value">{{nameServices()}}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'RecordSection',
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState(['companies', 'params', 'staffs', 'services', 'records'])
  },
  methods: {
    selectStaff(staff) {
      console.log(staff)
      //this.heading = filialId;
      this.$store.dispatch('params/setCurSection', 'MainSection')
      //this.$store.dispatch('params/setLoading', false)
      this.$store.dispatch('params/setSelStaff', staff.id)
      this.$store.dispatch('params/setTitleBlockStaff', staff.name)
      this.$store.dispatch('params/setBackLink', 'StaffsSection')
      console.log(this.params.curSection)
    },
    nameServices() {
      let strServices = "";
      let activeItems =  this.params.selServices
      this.services.services.services.forEach(function(el){
        if(activeItems.includes(el.id)) {
          if(strServices) {
            strServices += ", " + el.title
          }
          else {
            strServices = el.title
          }
        }
      })
      return strServices;
    }
  },
  created() {
    //this.$store.dispatch('staffs/getStaffs', {filialId: this.params.selCompany})
  },
  components: {

  }
}
</script>

<style lang="scss" scoped>
.rec-info {
  font-weight: bold;
  text-align: left;
  margin-bottom: 10px;
}
.record-info {
  text-align: left;
  margin-bottom: 20px;

  &__title {
    font-weight: 600;
    margin-bottom: 5px;
  }
}
</style>
