<template>
  <div>
    <Loader v-if="!staffs.staffs" />
    <div v-else v-for="v in staffs.staffs" :key="v.id">
      <div v-if="!params.customPrice || (v.id!='246570' && params.customPrice)" class="list-staff" @click="selectStaff(v)">
        <div class="list-staff__label">{{v.specialization}}</div>
        <div class="list-staff__title">{{v.name}}</div>
        <div class="list-staff__address">{{v.rating}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from "@/components/Loader";
export default {
  name: 'StaffsSection',
  data() {
    return {}
  },
  computed: {
    ...mapState(['companies', 'params', 'staffs'])
  },
  methods: {
    selectStaff(staff) {
      let curSection = 'ServicesSection'
      if(this.params.selDate && this.params.selServices.length > 0) {
        curSection = 'MainSection'
      }
      else if(this.params.selServices.length > 0) {
        curSection = 'DateTimeSection'
      }
      this.$store.dispatch('params/setCurSection', curSection)
      this.$store.dispatch('params/setSelStaff', staff)
      this.$store.dispatch('params/setTitleBlockStaff', staff.name)
      this.$store.dispatch('params/setBackLink', 'StaffsSection')
      console.log(this.params.curSection)
    }
  },
  created() {
    let params = {
      filialId: this.params.selCompany.id
    };
    if(this.params.selServices) {
      params.services = this.params.selServices;
    }
    if(this.params.selDate) {
      params.datetime = this.params.selDate + " " + this.params.selTime;
    }
    this.$store.dispatch('staffs/getStaffs', params)
  },
  components: {
    Loader
  }
}
</script>

<style lang="scss" scoped>
.list-staff {
  cursor: pointer;
  margin-bottom: 10px;
  padding: 15px;
  text-align: left;
  background: white;
  border-radius: 8px;

  &:hover {
    opacity: .8;
  }

  &__label {
    font-size: 12px;
    #color: #2d2d2d;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
  }

  &__address {
    font-size: 14px;
  }
}
</style>
