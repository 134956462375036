import api from "@/api";

export const state = {
    companies: null,
    company: null
};

export const getters = {
    companies(state) {
        return state.companies
    },
    company(state) {
        return state.company
    }
};

export const mutations = {
    SET_COMPANIES(state, res) {
        console.log(res);
        state.companies = res;
    },
    SET_COMPANY_INFO(state, res) {
        console.log(res);
        state.company = res;
    }
};

export const actions = {
    async getCompanies({ commit }) {
        try {
            const res = await api.methods.getCompanies()
            if(res['data']['success']) {
                commit('SET_COMPANIES', res['data']['data'])
            }
        }
        catch (e) {
            console.error(e)
            commit('params/SET_CUR_SECTION', 'ErrorSection', { root:true });
        }
    },
    async getCompany({ commit }, params) {
        try {
            const res = await api.methods.getCompany(params)
            if(res['data']['success']) {
                commit('SET_COMPANY_INFO', res['data']['data'])
            }
        }
        catch (e) {
            console.error(e)
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
