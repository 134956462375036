<template>
  <div class="accordion">
    <div class="faq">
      <p class="acc-title" @click="toggleAnswer">
        {{ acc.title }}
      </p>
      <p class="acc-info" ref="answer">
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccordionComponent',
  props: ["acc"],
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleAnswer() {
      if (this.isOpen) {
        this.collapse();
      } else {
        this.expand();
      }
      this.isOpen = !this.isOpen;
    },
    collapse() {
      // select the answer element
      const answer = this.$refs.answer;
      answer.style.height = 0;
    },
    expand() {
      // select answer element
      const answer = this.$refs.answer;

      // set its height to its normal scroll height to expand it fully
      answer.style.height = 'auto' //answer.scrollHeight + "px";
    }
  }
}
</script>

<style lang="scss">
.acc-title {
  background: hsl(35 10% 30% / 0.1);
  cursor: pointer;
  font-weight: bold;
  padding: 12px 0;
  transition: transform 0.2s;
  position: relative;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 0;

  &.acc-heading {
    pointer-events: none;
    background: transparent;
  }
}

.acc-title:hover {
  background: hsl(35 10% 30% / 0.15);
}

/*.acc-title::before {
  content: "✅";
  margin: 10px;
}*/

/* styles when the question is clicked */
.acc-title:active {
  transform: translateY(4px);
  box-shadow: none;
}

.acc-info {
  transition: 0.25s; /* smooth slide-in */
  height: 0; /* starts collapsed */
  overflow: hidden;
  line-height: 1.5;
  margin: 0;
}

/*.acc-info::before {
  content: "👉";
  margin-right: 10px;
}*/

/* style the toggleIcon */
.toggleIcon {
  font-size: 1.5em;
  font-weight: bold;
  position: absolute;
  right: 20px;
  display: inline-block;
  line-height: 0.5;
  color: #666;
}
</style>
