<template>
  <div class="backlink" @click="back()">
    Назад
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'BackLink',
  props: {},
  computed: {
    ...mapState(['params'])
  },
  methods: {
    back() {
      this.$store.dispatch('params/setCurSection', this.params.backLink)
      this.$store.dispatch('params/setBackLink', null)
    }
  }
}
</script>

<style lang="scss" scoped>
.backlink {
  padding: 10px;
  margin-bottom: 15px;
  background: white;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: .9;
  }
}
</style>
