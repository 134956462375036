import api from "@/api";

export const state = {
    record: null
};

export const getters = {};

export const mutations = {
    SET_RECORD(state, res) {
        state.record = res;
    }
};

export const actions = {
    async setRecord({ commit }, params) {
        try {
            const res = await api.methods.createRecord(params)
            if(res['data']['success']) {
                commit('SET_RECORD', res['data']['data'])
            }
            else {
                commit('params/SET_CUR_SECTION', 'ErrorSection', { root:true });
            }
        }
        catch (e) {
            console.error(e)
            commit('params/SET_CUR_SECTION', 'ErrorSection', { root:true });
        }
    },
    async sendSms({ commit }, params) {
        try {
            const res = await api.methods.postBookCode(params)
            if(res['data']['success']) {
                //commit('SET_RECORD', res['data']['data'])
            }
        }
        catch (e) {
            console.error(e)
            commit('params/SET_CUR_SECTION', 'ErrorSection', { root:true });
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
