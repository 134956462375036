<template>
  <div style="text-align: left;">
    <div>Действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность, физическое лицо дает свое согласие Индивидуальному предпринимателю Королёву Евгению Юрьевичу, ИНН 773472542704,&nbsp; ОГРИП 310774606400409&nbsp;&nbsp;(далее - Оператор), на обработку своих персональных данных со следующими условиями:</div>
    <ul>
      <li>Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.</li>
      <li>Согласие дается на обработку следующих моих персональных данных:
        <ul>
          <li>Персональные данные, не являющиеся специальными или биометрическими: фамилия, имя, отчество; номера контактных телефонов; адрес электронной почты; пол; дата рождения; категория клиента; реквизиты карты лояльности; сведения об оказываемых услугах.</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li>Цель обработки персональных данных:
        <ul>
          <li>предоставления пользователю возможности онлайн-записи на приём в студии BrowArt.</li>
        </ul>
        <ul>
          <li>приобретение Предложений BrowArt, получение услуг и товаров BrowArt.</li>
        </ul>
      </li>
      <li>В ходе обработки с персональными данными будут совершены следующие действия: сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение); извлечение; использование; блокирование; удаление; уничтожение.</li>
      <li>Персональные данные обрабатываются до достижения цели обработки либо до отзыва согласия на обработку персональных данных, в зависимости от того, какое из событий наступит раньше.</li>
      <li>Согласие может быть отозвано субъектом персональных данных или его представителем путем направления письменного заявления Оператору или его представителю по адресу, указанному в начале данного Согласия.</li>
      <li>В случае отзыва субъектом персональных данных или его представителем согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006 г.</li>
      <li>Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных в п.5 и п.6 данного Согласия.</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'DataProcessingSection',
  data() {
    return {}
  },
  computed: {
    ...mapState(['companies', 'params'])
  },
  methods: {
    selectFilial(filialId) {
      this.heading = filialId;
    },
    async requestData(id) {
      try {
        const data = await this.getImages({
          album_id: id,
          order_by: { sort: 'desc' },
          where: {
            album_id: {
              value: id,
              operator: '='
            }
          }
        });
        this.images = [...data];
      } catch (e) {
        console.error(e);
      }

      this.isLoading = false;
    },
  },
  mounted() {
    this.$store.dispatch('params/setLoading', true)
    this.$store.dispatch('companies/getCompany', {id: this.params.selCompany.id})
    this.$store.dispatch('params/setLoading', false)
  },
}
</script>

<style lang="scss" scoped>
.info-block {
  text-align: left;
  &__label {
    color: rgb(128, 128, 128);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    max-width: 100%;
    text-align: left;
  }
}
</style>
