<template>
  <div class="form-record">
    Код подтверждения<br><br>
    <input v-model="smsCode" type="text" placeholder="Код подтверждения">
    <div class="btn-submit" @click="sendRecord()">Подтвердить</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SmsCodeSection',
  data() {
    return {
      smsCode: ""
    }
  },
  computed: {
    ...mapState(['companies', 'params', 'staffs'])
  },
  methods: {
    sendRecord() {
      let info = {
          filialId: this.params.selCompany.id,
          staffId: this.params.selStaff.id,
          date: this.params.selDate,
          time: this.params.selTime,
          services: this.params.selServices,
          fullname: this.params.userName,
          phone: this.params.userPhone,
          code: this.smsCode,
          utm: this.params.utm
      }
      this.$store.dispatch('records/setRecord', info)
      this.$store.dispatch('params/setBackLink', null)
      this.$store.dispatch('params/setCurSection', 'SuccessSection')
    }
  },
  created() {
    //this.$store.dispatch('staffs/getStaffs', {filialId: this.params.selCompany})
    //this.isLoading = false
  },
  components: {

  }
}
</script>

<style lang="scss" scoped>
.form-record {
  input[type=text] {
    display: block;
    padding: 15px 10px;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
    outline: none;
    border: 1px solid #fefefe;
    border-radius: 5px;
    text-align: center;
  }
  .btn-submit {
    border: 1px solid black;
    padding: 10px;
    cursor: pointer;

    &:hover {
      opacity: .9;
    }
  }
}
</style>
