export default function(instance) {
    return {
        getCompanies(payload) {
            return instance.post(instance.defaults.baseUrl + 'getCompanies/', payload)
        },
        getCompany(payload) {
            return instance.post(instance.defaults.baseUrl + 'getCompany/', payload)
        },
        getComments(payload) {
            return instance.post(instance.defaults.baseUrl + 'getComments/', payload)
        },
        getBookServices(payload) {
            return instance.post(instance.defaults.baseUrl + 'getBookServices/', payload)
        },
        getBookStaff(payload) {
            return instance.post(instance.defaults.baseUrl + 'getBookStaff/', payload)
        },
        getBookDates(payload) {
            return instance.post(instance.defaults.baseUrl + 'getBookDates/', payload)
        },
        getBookTimes(payload) {
            return instance.post(instance.defaults.baseUrl + 'getBookTimes/', payload)
        },
        postBookCode(payload) {
            return instance.post(instance.defaults.baseUrl + 'postBookCode/', payload)
        },
        createRecord(payload) {
            return instance.post(instance.defaults.baseUrl + 'createRecord/', payload)
        },
    }
}
