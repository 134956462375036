<template>
  <div>
    <Loader v-if="!companies.company" />
    <div v-else>
      <div v-if="companies.company.title">{{companies.company.title}}</div>
      <div v-if="companies.company">
        <div class="info-block">
          <div class="info-block__label">О компании</div>
          <div class="info-block__desc" v-html="companies.company.description"></div>
        </div>
        <div class="info-block">
          <div class="info-block__label">Время работы:</div>
          <div class="info-block__desc">{{companies.company.schedule}}</div>
        </div>
        <div class="info-block">
          <div class="info-block__label">Адрес:</div>
          <div class="info-block__desc">{{companies.company.address}}</div>
        </div>
        <div class="info-block">
          <div class="info-block__label">Контакты</div>
          <div class="info-block__desc">{{companies.company.phone}}</div>
          <div class="info-block__desc">{{companies.company.site}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CompanySection',
  data() {
    return {}
  },
  computed: {
    ...mapState(['companies', 'params'])
  },
  methods: {
    selectFilial(filialId) {
      this.heading = filialId;
    },
    async requestData(id) {
      try {
        const data = await this.getImages({
          album_id: id,
          order_by: { sort: 'desc' },
          where: {
            album_id: {
              value: id,
              operator: '='
            }
          }
        });
        this.images = [...data];
      } catch (e) {
        console.error(e);
      }

      this.isLoading = false;
    },
  },
  mounted() {
    this.$store.dispatch('params/setLoading', true)
    this.$store.dispatch('companies/getCompany', {id: this.params.selCompany.id})
    this.$store.dispatch('params/setLoading', false)
  },
}
</script>

<style lang="scss" scoped>
.info-block {
  text-align: left;
  &__label {
    color: rgb(128, 128, 128);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    max-width: 100%;
    text-align: left;
  }
}
</style>
