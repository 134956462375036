import { createStore } from 'vuex'
import app from './modules/app'
import companies from './modules/companies'
import params from "@/store/modules/params";
import staffs from "@/store/modules/staffs";
import datetime from "@/store/modules/datetime";
import services from "@/store/modules/services";
import records from "@/store/modules/records";

const store = createStore({
    modules: {
        app,
        companies,
        params,
        staffs,
        datetime,
        services,
        records
    },
    state: {},
    getters: {
      getLoad(state) {
          return state.count
      }
    },
    mutations: {
        increment (state) {
            state.count++
        },
        SET_LOAD(state, payload) {
            state.count = payload
        }
    },
    actions: {}
})

export default store
