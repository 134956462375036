<template>
  <div v-if="!params.showWidget" @click="setShowWidget()" class="button-widget">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_97_69)">
        <path d="M0 25.3334C0.00211714 27.1009 0.705176 28.7954 1.95496 30.0451C3.20474 31.2949 4.89921 31.998 6.66667 32.0001H25.3333C27.1008 31.998 28.7953 31.2949 30.045 30.0451C31.2948 28.7954 31.9979 27.1009 32 25.3334V13.3334H0V25.3334ZM22.6667 19.3334C23.0622 19.3334 23.4489 19.4507 23.7778 19.6705C24.1067 19.8903 24.3631 20.2026 24.5144 20.5681C24.6658 20.9335 24.7054 21.3357 24.6282 21.7236C24.5511 22.1116 24.3606 22.4679 24.0809 22.7476C23.8012 23.0274 23.4448 23.2178 23.0568 23.295C22.6689 23.3722 22.2668 23.3326 21.9013 23.1812C21.5358 23.0298 21.2235 22.7735 21.0037 22.4446C20.784 22.1157 20.6667 21.729 20.6667 21.3334C20.6667 20.803 20.8774 20.2943 21.2525 19.9192C21.6275 19.5441 22.1362 19.3334 22.6667 19.3334ZM16 19.3334C16.3956 19.3334 16.7822 19.4507 17.1111 19.6705C17.44 19.8903 17.6964 20.2026 17.8478 20.5681C17.9991 20.9335 18.0387 21.3357 17.9616 21.7236C17.8844 22.1116 17.6939 22.4679 17.4142 22.7476C17.1345 23.0274 16.7781 23.2178 16.3902 23.295C16.0022 23.3722 15.6001 23.3326 15.2346 23.1812C14.8692 23.0298 14.5568 22.7735 14.3371 22.4446C14.1173 22.1157 14 21.729 14 21.3334C14 20.803 14.2107 20.2943 14.5858 19.9192C14.9609 19.5441 15.4696 19.3334 16 19.3334ZM9.33333 19.3334C9.7289 19.3334 10.1156 19.4507 10.4445 19.6705C10.7734 19.8903 11.0297 20.2026 11.1811 20.5681C11.3325 20.9335 11.3721 21.3357 11.2949 21.7236C11.2177 22.1116 11.0273 22.4679 10.7475 22.7476C10.4678 23.0274 10.1115 23.2178 9.72351 23.295C9.33555 23.3722 8.93342 23.3326 8.56797 23.1812C8.20251 23.0298 7.89016 22.7735 7.67039 22.4446C7.45063 22.1157 7.33333 21.729 7.33333 21.3334C7.33333 20.803 7.54405 20.2943 7.91912 19.9192C8.29419 19.5441 8.8029 19.3334 9.33333 19.3334Z" fill="white"/>
        <path d="M25.3333 2.66667H24V1.33333C24 0.979711 23.8595 0.640573 23.6095 0.390524C23.3594 0.140476 23.0203 0 22.6667 0C22.313 0 21.9739 0.140476 21.7239 0.390524C21.4738 0.640573 21.3333 0.979711 21.3333 1.33333V2.66667H10.6667V1.33333C10.6667 0.979711 10.5262 0.640573 10.2761 0.390524C10.0261 0.140476 9.68696 0 9.33333 0C8.97971 0 8.64057 0.140476 8.39052 0.390524C8.14048 0.640573 8 0.979711 8 1.33333V2.66667H6.66667C4.89921 2.66878 3.20474 3.37184 1.95496 4.62163C0.705176 5.87141 0.00211714 7.56587 0 9.33333L0 10.6667H32V9.33333C31.9979 7.56587 31.2948 5.87141 30.045 4.62163C28.7953 3.37184 27.1008 2.66878 25.3333 2.66667Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_97_69">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
  <div v-else class="wrap-widget">
    <div id="app-widget">
      <WidgetHeader :msg="params.heading" :company="params.selCompany" />
      <BackLink v-if="params.backLink" />
      <transition name="component-fade" mode="out-in">
        <component :is="params.curSection"></component>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WidgetHeader from './components/Header.vue'
import BackLink from "@/components/BackLink";
import CompaniesSection from "@/components/CompaniesSection";
import CompanySection from "@/components/CompanySection";
import MainSection from "@/components/MainSection";
import StaffsSection from "@/components/StaffsSection";
import DateTimeSection from "@/components/DateTimeSection";
import ServicesSection from "@/components/ServicesSection";
import RecordSection from "@/components/RecordSection";
import SmsCodeSection from "@/components/SmsCodeSection";
import SuccessSection from "@/components/SuccessSection";
import ErrorSection from "@/components/ErrorSection";
import DataProcessing from "@/components/DataProcessingSection";

export default {
  name: 'App',
  data() {
    return {
      section: 2
    }
  },
  computed: {
    ...mapState(['companies', 'params', 'staff', 'datetime', 'services'])
  },
  methods: {
    setShowWidget() {
      this.$store.dispatch('params/setShowWidget', true)
    },
    getCookie(name) {
      let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)" //eslint-disable-line
      ));
      return matches ? decodeURIComponent(matches[1]) : null;
  },
  getCounter(ymCounterNum, callback, interval) {
    if (!callback) return;
    if (!ymCounterNum) {
      let metrikaObj  = (window.Ya && (window.Ya.Metrika || window.Ya.Metrika2)) || null;
      ymCounterNum = (metrikaObj && metrikaObj.counters && (metrikaObj.counters() || [0])[0].id) || 0;
    }
    let ymCounterObj = window['yaCounter' + ymCounterNum] || null;
    if (ymCounterObj) return (callback(ymCounterObj, ymCounterNum), undefined);
    console.log(interval);
    //setTimeout(function () { this.getCounter(ymCounterNum, callback, interval); }, interval || 250);
  }
    /*selectFilial(filialId) {
      //console.log(filialId);
      //this.heading = filialId;
    }*/
  },
  created() {
    let utmObj = {
      utm_source: null,
      utm_content: null,
      utm_medium: null,
      utm_campaign: null,
      utm_term: null
    }
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get("utm_source")) {
      utmObj = {
        utm_source: urlParams.get("utm_source"),
        utm_content: urlParams.get("utm_content"),
        utm_medium: urlParams.get("utm_medium"),
        utm_campaign: urlParams.get("utm_campaign"),
        utm_term: urlParams.get("utm_term")
      }

      var now = new Date();
      var time = now.getTime();
      var expiryMinutes = 40;
      var expireTime = time+(expiryMinutes*60*1000);
      now.setTime(expireTime);
      var expires = "expires="+now.toGMTString();
      document.cookie = "utm_source=" +utmObj.utm_source+ ";" +expires+ "; path=/";
      document.cookie = "utm_medium=" +utmObj.utm_medium+ ";" +expires+ "; path=/";
      document.cookie = "utm_campaign=" +utmObj.utm_campaign+ ";" +expires+ "; path=/";
      document.cookie = "utm_content=" +utmObj.utm_content+ ";" +expires+ "; path=/";
      document.cookie = "utm_term=" +utmObj.utm_term+ ";" +expires+ "; path=/";
    }
    else {
      let utm_source = this.getCookie('utm_source');
      //console.log(utm_source);
      if(utm_source !== null && utm_source.length > 0) {
        utmObj = {
          utm_source: this.getCookie("utm_source"),
          utm_content: this.getCookie("utm_content"),
          utm_medium: this.getCookie("utm_medium"),
          utm_campaign: this.getCookie("utm_campaign"),
          utm_term: this.getCookie("utm_term")
        }
      }
    }
    let metrikaCounter = null;
    this.getCounter(null, function (counter) { metrikaCounter = counter; });
    //console.log(metrikaCounter)
    if (metrikaCounter) {
      this.$store.dispatch('params/setMetrikaCounter', metrikaCounter)
    }

    //console.log(utmObj);
    if(urlParams.get("service_id")) {
      this.$store.dispatch('params/setDefaultServiceId', urlParams.get("service_id"))
    }
    this.$store.dispatch('params/setUtm', utmObj)
    if(document.querySelector('body[data-custom-widget]')) {
      document.querySelectorAll("[href='https://n101984.yclients.com/']")?.forEach( el => {
            el.addEventListener('click', (e) => {
              e.preventDefault()
              if(e.target.hasAttribute('data-service-id')) {
                this.$store.dispatch('params/setDefaultServiceId', e.target.getAttribute('data-service-id'))
              }
              this.setShowWidget()
            })
        }
      )
    }
    if(utmObj.utm_content !== null) {
      if(utmObj.utm_content == "price25") {
        this.$store.dispatch('params/setCustomPrice', "2500")
      }
      else if(utmObj.utm_content == "price28") {
        this.$store.dispatch('params/setCustomPrice', "2800")
      }
      else if(utmObj.utm_content == "price33") {
        this.$store.dispatch('params/setCustomPrice', "3300")
      }
      else if(utmObj.utm_content == "price38") {
        this.$store.dispatch('params/setCustomPrice', "3800")
      }
      else if(utmObj.utm_content == "price43") {
        this.$store.dispatch('params/setCustomPrice', "4300")
      }
      else if(utmObj.utm_content == "price48") {
        this.$store.dispatch('params/setCustomPrice', "4800")
      }
      else if(utmObj.utm_content == "price51") {
        this.$store.dispatch('params/setCustomPrice', "5100")
      }
      if(this.params.customPrice) {
        this.$store.dispatch('params/setDefaultServiceId', '1627398')
      }
    }
    if(this.params.customPrice) {
      this.$store.dispatch('companies/getCompanies', 1000).then(() => {
        let st = this.$store
        this.companies.companies.forEach(function(el){
          console.log(el.id);
          if(el.id == '102428') {
            st.dispatch('params/setSelCompany', el)
          }
        })
        this.$store.dispatch('params/setSelServices', [])
        this.$store.dispatch('params/setSelDate', null)
        this.$store.dispatch('params/setSelTime', null)
        this.$store.dispatch('params/setSelStaff', null)
        this.$store.dispatch('params/setTitleBlockStaff', 'Выбрать специалиста')
        this.$store.dispatch('params/setTitleBlockServices', 'Выбрать услуги')
        this.$store.dispatch('params/setTitleBlockDatetime', 'Выбрать время')

        if (this.params.defaultServiceId) {
          let params = {
            filialId: this.params.selCompany.id
          };
          let ds = this.params.defaultServiceId
          this.$store.dispatch('services/getServices', params).then(() => {
            let st = this.$store

            this.services.services.services.forEach(function (el) {
              if (el.id == ds) {
                st.dispatch('params/setSelServices', [el.id])
                st.dispatch('params/setTitleBlockServices', el.title)
              }
            })
          })
        }
        this.$store.dispatch('params/setCurSection', 'MainSection')
      })
    }
  },
  components: {
    CompaniesSection,
    CompanySection,
    WidgetHeader,
    BackLink,
    MainSection,
    StaffsSection,
    DateTimeSection,
    ServicesSection,
    RecordSection,
    SmsCodeSection,
    SuccessSection,
    ErrorSection,
    DataProcessing
  }
}
</script>

<style lang="scss">
.over-widget {
  overflow: hidden;
}
.button-widget {
  position: fixed;
  bottom: 35px;
  right: 100px;
  background-color: #A77FBA;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 2px #fff;
  border-radius: 50%;
  z-index: 99;
  animation: dindin 2.5s linear infinite;
  transform: rotate(0);
  transition: .3s linear;

  &:hover {
    opacity: .9;
  }

  @media screen and (max-width: 768px) {
    right: 10px;
    bottom: 70px;
  }

}
@keyframes dindin {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(-25deg);
  }
  20% {
    transform: rotate(0);
  }
  30% {
    transform: rotate(25deg);
  }
  40% {
    transform: rotate(0);
  }
  100% {
    transform: translate(0);
  }
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}
/* Анимации появления и исчезновения могут иметь    */
/* различные продолжительности и функции плавности. */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.wrap-widget {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 105;
  background: rgba(0, 0, 0, 0.5);
}
#app-widget {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  border: 0;
  padding: 15px;
  max-width: 400px;
  width: 100%;
  background-color: #f5f5f5;

  @media (max-width: 450px) {
    left: 0;
    width: initial;
    max-width: initial;

  }
  * {
    box-sizing: border-box;
  }

  .btn-confirm {
    background: #0fd70f;
    padding: 10px;
    border-radius: 8px;
    margin-top: 5px;
    cursor: pointer;
    font-weight: 700;

    &:hover {
      opacity: .9;
    }
  }
}
</style>
