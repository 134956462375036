import { createApp } from 'vue'
import Datepicker from 'vue-datepicker'
import App from './App.vue'
import store from './store/index'
//import ApiPlugin from './plugins/api'

const app = createApp(App)
app.use(store)
app.use(Datepicker)
//app.use(ApiPlugin)
app.mount('#app')
