<template>
  <div class="error">
    Случилась ошибка( <br> Попробуйте позже!
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ErrorSection',
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState(['companies', 'params', 'staffs'])
  },
  methods: {
    selectStaff(staff) {
      console.log(staff)
      //this.heading = filialId;
      this.$store.dispatch('params/setCurSection', 'MainSection')
      //this.$store.dispatch('params/setLoading', false)
      this.$store.dispatch('params/setSelStaff', staff.id)
      this.$store.dispatch('params/setTitleBlockStaff', staff.name)
      this.$store.dispatch('params/setBackLink', 'StaffsSection')
      console.log(this.params.curSection)
    }
  },
  created() {
    //this.$store.dispatch('staffs/getStaffs', {filialId: this.params.selCompany})
  },
  components: {

  }
}
</script>

<style lang="scss" scoped>
.error {
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
}
</style>
