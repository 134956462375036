<template>
  <div class="loader">
    <svg style="width: 50px; height: 50px;" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
      <linearGradient id='a5'>
        <stop offset='0' stop-color='#A77FBA' stop-opacity='0'></stop>
        <stop offset='1' stop-color='#A77FBA'></stop>
      </linearGradient>
      <circle fill='none' stroke='url(#a5)' stroke-width='15' stroke-linecap='round' stroke-dasharray='0 44 0 44 0 44 0 44 0 360' cx='100' cy='100' r='70' transform-origin='center'>
        <animateTransform type='rotate' attributeName='transform' calcMode='discrete' dur='2' values='360;324;288;252;216;180;144;108;72;36' repeatCount='indefinite'></animateTransform>
      </circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'WidgetLoader'
}
</script>

<style lang="scss">
.loader {
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.animation-loader {
  animation: scaleLogo 2s ease infinite;
}

@keyframes scaleLogo {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
